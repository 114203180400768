export const API_URL = "https://api.secondstage.app"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"light",
    gradients: {
        default: ['#6ed09b', '#009ba8'],
        home_question: ['#6ed09b', '#009ba8'],
        applausometro: ['#6ed09b', '#009ba8'],
        siNo:['#009ba8', '#6ed09b'],
        sentiment: ['#009ba8', '#6ed09b'],
        wordcloud: ['#009ba8', '#6ed09b'],
        qna: ['#009ba8', '#6ed09b'],
    },
    primary: '#009ba8',
    secondary: '#263238',
    accent: '#6ed09b',
    text: '#009ba8',
    background:"#ffffff",
    poll:{
        default:"#6ed09b",
        answered:"#009ba8",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}